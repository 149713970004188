<template>
  <div id="QA_qrcode">
    <h1 class="title">Scan QR Code</h1>
    <p class="is-size-4 has-text-weight-bold has-text-centered">{{ currentDeal.business.name }}</p>
    <p class="is-size-4 has-text-centered">{{ currentDeal.description }}</p>
    <p class="is-italic" style="margin-top: 1.5rem;">Upon completing your purchase, show this screen to the merchant. You will then be shown a QR code to scan to earn your reward.</p>

    <div
      v-if="error"
      class="notification is-danger"
    >
      {{ error }}
    </div>
    <Loader
        v-if="loading || submitting"
    />
    <button
      v-if="scanAllowed && !scanning"
      class="button is-link"
      style="margin-top: 20px;"
      @click="scan()"
    >
      Start Scan
    </button>
    <button 
      class="button"
      style="margin-top: 20px;"
      v-if="!scanning"
      @click="back()">
      cancel
    </button>
  </div>
</template>

<script>
import DealScanMixin from "@/mixins/DealScanMixin.js";
import Loader from "@/components/Loader.vue"
import {AuthError, NetworkError} from "@/errors";

export default {
  props: {
    orgSlug: {
      type: String,
      required: true
    },
    slug: {
      type: String,
      required: true
    },
    locationId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      noStreamApiSupport: false,
      loading: false,
      submitting: false,
      scanning: false,
      error: null
    };
  },
  mixins: [DealScanMixin],
  components: {
    Loader
  },
  computed: {
    loggedIn: function() {
      return this.$store.getters.isLoggedIn;
    },
    currentUser: function() {
      return this.$store.getters.currentUser;
    },
    isAdmin: function() {
      return this.$store.getters.isAdmin
    },
    currentDeal: function() {
      return this.$store.getters.currentDeal;
    },
    currentBusiness: function() {
      return this.$store.getters.currentBusiness
    },
    canEdit: function() {
      return ((this.$store.getters.canEdit || this.isAdmin)
        && typeof this.currentDeal.slug !== 'undefined'
        && this.currentDeal.business.slug === this.currentBusiness.slug
      ) ? true : false;
    },
    currentDealLocation: function() {
      return this.currentDeal.business.locations.find(l => l.id == this.locationId)
    },
    isPhysicalLocation: function() {
      if (!this.currentDealLocation) {
        return null
      }
      return this.currentDealLocation.is_physical_location
    }
  },
  mounted() {
    this.updateData()
    if (!this.scanAllowed) {
      this.error = this.reasonScanNotAllowed
    }
  },
  methods: {
    updateData() {
      if (!this.currentDeal || this.currentDeal.slug !== this.slug) {
        this.$store.dispatch("getDeal", this.slug)
          .then(() => {
            if (!this.currentDeal) {
              this.error = "Deal not found!"
            } else if (!this.scanAllowed) {
              this.error = this.reasonScanNotAllowed
            }
          })
      }
    },
    scan() {
      this.scanning = true;
      window.NativeScript.scan().then((result) => { this.scanning = false; if (result) { this.onDecode(result); } });
    },
    back() {
      this.$router.go(-1);
    },

    onDecode(result) {
      this.submitting = true;

      const params = {
        user_slug: this.currentUser.slug,
        deal_slug: this.currentDeal.slug,
        deal_code: result,  // this may be either just the deal code or the full URL, the API will handle either
        location_id: parseInt(this.locationId)
      }

      this.$store.dispatch("scanClaimDeal", params)
          .then(response => {
            console.log(response);

            const path = `/deal/${this.orgSlug}/${this.slug}/l/${this.locationId}/scan-success`
            this.$router.push(path);
          })
          .catch(error => {
            if (error instanceof AuthError || error instanceof NetworkError) {
              throw error
            } else {
              this.error = error.message
            }
          })
          .finally(() => {
            this.submitting = false
          })
    },
    logErrors(promise) {
      promise.catch(console.error);
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        switch(error.name) {
          case "StreamApiNotSupportedError":
            this.noStreamApiSupport = true;
            this.error = "Your browser doesn't support necessary camera functionality. Please try a different browser or device."
            break;
          case "NotAllowedError":
            this.error = "Camera access has been denied, but we need it to scan the QR code. Please allow camera access and try again."
            break;
          case "NotFoundError":
            this.error = "We couldn't find a camera, but need one to scan the QR code. Please try a different device."
            break;
          case "NotSupportedError":
          case "NotReadableError":
          case "OverconstrainedError":
          default:
              this.error = "We're having trouble accessing your camera. Please try a different browser or device.";
            break;
        }
      } finally {
        this.loading = false
      }
    }
  }
};
</script>

<style scoped>
#QA_qrcode {
  padding: 20px;
}
.loader-wrapper {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
}
</style>
